//* This function is used to get the initial values for the tracking configurations mutating the second input object
export default function getInitialValuesViaTrackingConfigurations(
  configObjFromAPI: { [key: string]: any }, // The first input object with initial values
  allEmptyConfigObject: { [key: string]: any } // The second input object with empty values
): { [key: string]: any } {
  // The return type is an object with string keys and any values
  // Create a new object by spreading allEmptyConfigObject
  const newConfigObject = { ...allEmptyConfigObject };

  // Iterate over each key in the first object (configObjFromAPI)
  for (const key in configObjFromAPI) {
    // Check if the value in the first object is not null
    // and if the second object (newConfigObject) has this key
    if (configObjFromAPI[key] !== null && newConfigObject.hasOwnProperty(key)) {
      // If both conditions are met, set the value of the new object to the value from the first object
      newConfigObject[key] = configObjFromAPI[key];
    }
  }
  // Return the new object
  return newConfigObject;
}
