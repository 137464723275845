import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import { useTranslation } from 'react-i18next';
import {
  initialInputData,
  transformInputData,
} from '../../../../lib/constants/trackingClassesData';
import post_integration_api_call from '../../../../lib/api/integration/post_integration_api_call';
import { useState } from 'react';
import getInitialValuesViaTrackingConfigurations from '../../../../lib/helper-functions/getInitialValuesViaTrackingConfigurations';

interface Props {
  setIsTrackerON: (value: boolean) => void;
  isTrackerON: boolean;
  isAutofillEnabled: boolean;
  data: any;
  setScriptKey: (key: string) => void;
}

const TrackerSwitch = ({
  setIsTrackerON,
  isTrackerON,
  isAutofillEnabled,
  data,
  setScriptKey,
}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const trackingInputValues =
    data?.length > 0
      ? getInitialValuesViaTrackingConfigurations(
          data[data?.length - 1]?.configuration,
          transformInputData(initialInputData)
        )
      : transformInputData(initialInputData);

  async function onConfirmHandler(isEnabled: boolean) {
    setIsLoading(true);
    const isEverythingNull = !Object.values(trackingInputValues).some(
      (value) => value !== ''
    );

    const trackerSwitchObj: { is_tracker_active: 'true' | 'false' } = {
      is_tracker_active: isEnabled ? 'true' : 'false',
    };

    const autofillEnabledObj: { is_autofill_enabled: 'true' | 'false' } = {
      is_autofill_enabled: isAutofillEnabled ? 'true' : 'false',
    };

    try {
      const sendingData: any = {
        name: 'checkout-tracking-class-names',
        type: 'tracker',
        configuration: isEverythingNull
          ? { key: 'value' }
          : trackingInputValues,
      };

      if (data?.length > 0) {
        sendingData['_method'] = 'put';
      }

      const customMsg = isEnabled
        ? t('Tracking-Enabled-msg')
        : t('Tracking-Disabled-msg');

      const res = await post_integration_api_call(
        sendingData,
        data?.length > 0 ? data[data?.length - 1]?.slug : undefined,
        autofillEnabledObj,
        trackerSwitchObj,
        customMsg
      );
      if (res?.api_key) {
        setScriptKey(res?.api_key);
      }
      setIsLoading(false);
    } catch (exception) {
      console.log('Error during authentication request');
      setIsLoading(false);
      // submitLogToBugsnag('error', `${exception}`);
    }
  }

  return (
    <div className='normalForm'>
      <div className='normalForm__top'>
        <h2>{t('TrackerActivationSectionTitle')}</h2>
        <hr />
        <div className='shopPage__container1-alignLeft'>
          <ToggleSwitch
            isOn={isTrackerON}
            title={t('TrackerActivationToggle')}
            onClick={() => {
              setIsTrackerON(!isTrackerON);
              onConfirmHandler(!isTrackerON);
            }}
            classes='margin-top-2rem'
            id='Tracking-TrackerSwitch'
            disabled={isLoading}
          />
        </div>
        <span>{t('TrackerActivationDescription')}</span>
      </div>
    </div>
  );
};

export default TrackerSwitch;
