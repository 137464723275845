import { useEffect, useState } from 'react';
import ChartWithConfig from '../ChartWithConfig';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import get_graph_data_api_call from '../../../lib/api/stats/get_graph_data_api_call';
import UserDemographicsMapChart from './UserDemographicsMapChart';
import isNotEmptyString from '../../../lib/helper-functions/isNotEmptyString';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const MainUserDemographicsChart = ({
  timeFilter,
  customPeriodFilterDateRange,
  eventFilter,
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    '?graph_type=user_demography_graph&time_range=all'
  );

  const [response, setResponse] = useState<any>('initial');

  const [title, setTitle] = useState<string>(t('UserDemographics'));
  const [comment, setComment] = useState<string>(t('UserDemographicsComment'));

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        setResponse(res);
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }

    let eventURLParam = '';
    if (isNotEmptyString(eventFilter)) {
      eventURLParam = `&event_type=${eventFilter}`;
    }

    setParams(
      `?graph_type=user_demography_graph&time_range=${timeFilter}${dateRangeURLParam}${eventURLParam}`
    );
  }, [timeFilter, customPeriodFilterDateRange, eventFilter]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      setResponse('initial');
      getGraphData();
    }
  }, [params, stats]);

  return (
    <ChartWithConfig isHeightAuto={true} title={title} comment={comment}>
      <UserDemographicsMapChart apiResponse={response} />
    </ChartWithConfig>
  );
};

export default MainUserDemographicsChart;
