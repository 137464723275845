import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import get_graph_data_api_call from '../../../lib/api/stats/get_graph_data_api_call';
import ChartWithConfig from '../ChartWithConfig';
import AverageCartValueLineChart from './AverageCartValueLineChart';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const MainAverageCartValueChart = ({
  timeFilter,
  customPeriodFilterDateRange,
  eventFilter,
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    '?graph_type=average_cart_value&group_by=last_3_months'
  );
  const [response, setResponse] = useState<any>('initial');

  //Group by options
  const [groupBy, setGroupBy] = useState<string>('last_3_months');
  const groupByOptionsArr = [
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_4_weeks', OptionName: t('Last4Weeks') },
    { OptionValue: 'last_3_months', OptionName: t('Last3Months') },
  ];

  const [title, setTitle] = useState<string>(t('AverageCartValue'));
  const [comment, setComment] = useState<string>(
    t('AverageCartValueLast3MonthsComment')
  );

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        setResponse(res);
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    setResponse('initial');
    setParams(`?graph_type=average_cart_value&group_by=${groupBy}`);
    if (groupBy === 'last_3_months') {
      setComment(t('AverageCartValueLast3MonthsComment'));
    } else if (groupBy === 'last_4_weeks') {
      setComment(t('AverageCartValueLast4WeeksComment'));
    } else if (groupBy === 'last_7_days') {
      setComment(t('AverageCartValueLast7DaysComment'));
    }
  }, [groupBy]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  return (
    <ChartWithConfig
      groupBy={groupBy}
      setGroupBy={setGroupBy}
      groupByOptionsArr={groupByOptionsArr}
      title={title}
      comment={comment}
    >
      <AverageCartValueLineChart apiResponse={response} groupBy={groupBy} />
    </ChartWithConfig>
  );
};

export default MainAverageCartValueChart;
