import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import '../../styles/components/subPageContentHolder/customTimeRangePicker.scss';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
  toggleCustomTimePopupModal: () => void;
  setCustomPeriodFilterDateRange: (value: {
    start_date: string | undefined;
    end_date: string | undefined;
  }) => void;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
};

// Custom theme for dark mode
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
// Custom theme for light mode
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const CustomTimeRangePicker = ({
  toggleCustomTimePopupModal,
  setCustomPeriodFilterDateRange,
  customPeriodFilterDateRange,
}: Props) => {
  const { t } = useTranslation();
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const [message, setMessage] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(
    customPeriodFilterDateRange?.start_date
      ? dayjs(customPeriodFilterDateRange.start_date)
      : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    customPeriodFilterDateRange?.end_date
      ? dayjs(customPeriodFilterDateRange.end_date)
      : null
  );

  const handleStartDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue);
    setMessage('');
  };
  const handleEndDateChange = (newValue: Dayjs | null) => {
    setEndDate(newValue);
    setMessage('');
  };

  const handleDoneClick = () => {
    if (!startDate) {
      setMessage(t('PleaseSelectStartDate'));
    } else if (!endDate) {
      setMessage(t('PleaseSelectEndDate'));
    } else if (startDate > endDate) {
      setMessage(t('EndDateShouldBeGreaterThanStartDate'));
    } else {
      setMessage('');
      const formattedStartDate = startDate.format('YYYY-MM-DD');
      const formattedEndDate = endDate.format('YYYY-MM-DD');

      setCustomPeriodFilterDateRange({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      });

      toggleCustomTimePopupModal();
    }
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          className={`customTimeRangePicker ${isDarkMode ? 'dark-mode' : ''}`}
        >
          <div className='customTimeRangePicker__text'>
            <h3>{t('SelectDateRange')}</h3>
          </div>

          <div className='customTimeRangePicker__dates'>
            <DatePicker
              label={t('StartDate')}
              value={startDate}
              onChange={handleStartDateChange}
              disableFuture
              format='DD-MM-YYYY'
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: '1.2rem', // Increase font size of the DatePicker input (value area)
                },
                '& .MuiInputLabel-root': {
                  fontSize: '1.5rem', // Increase font size of the label
                },
                '& .MuiInputBase-input': {
                  fontSize: '1.2rem', // Increase font size of the input field (this includes the placeholder text)
                },
              }}
            />
            <span>-</span>
            <DatePicker
              label={t('EndDate')}
              value={endDate}
              onChange={handleEndDateChange}
              disableFuture
              format='DD-MM-YYYY'
              minDate={startDate !== null ? startDate : undefined} //* to prevent selecting an end date before the start date
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: '1.2rem', // Increase font size of the DatePicker input (value area)
                },
                '& .MuiInputLabel-root': {
                  fontSize: '1.5rem', // Increase font size of the label
                },
                '& .MuiInputBase-input': {
                  fontSize: '1.2rem', // Increase font size of the input field (this includes the placeholder text)
                },
              }}
            />
          </div>

          <div className='customTimeRangePicker__message'>
            {message && <p>{message}</p>}
          </div>

          <div className='customTimeRangePicker__btn'>
            <MainSquareButton
              disabled={startDate === null || endDate === null}
              value={t('Select')}
              onClick={handleDoneClick}
            />
          </div>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CustomTimeRangePicker;
