import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/components/subPageContentHolder/dashboardFilter.scss';
import Popup from '../../ui/popup/Popup';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { eventSVG } from '../../lib/allCustomSVGs';

interface Props {
  dashboardEventFilterHandler: (value: string) => void;
  eventFilter: string;
}

const DashboardFilterEvent = ({
  dashboardEventFilterHandler,
  eventFilter,
}: Props) => {
  const { t } = useTranslation();

  const eventFilterOptionsArr = [
    { OptionValue: 'purchase', OptionName: t('Purchase') },
    { OptionValue: 'pay_button', OptionName: t('PayButton') },
    { OptionValue: 'add_to_cart', OptionName: t('AddToCart') },
    { OptionValue: 'go_to_checkout', OptionName: t('GoToCheckout') },
  ];

  //custom event popup
  const [isCustomEventPopupOpen, setIsCustomEventPopupOpen] = useState(false);
  const toggleCustomEventPopupModal = () => {
    setIsCustomEventPopupOpen(!isCustomEventPopupOpen);
  };

  return (
    <>
      <div
        onClick={toggleCustomEventPopupModal}
        className='dashboardFilter__event dashboardFilter__event-desktop'
      >
        <div className='dashboardFilter__event--title'>
          <span>{t('SelectEvent')}:</span>
          <span>
            {
              eventFilterOptionsArr.find(
                (item) => item.OptionValue === eventFilter
              )?.OptionName
            }
          </span>
        </div>
      </div>

      {/* mobile */}
      <div
        onClick={toggleCustomEventPopupModal}
        className='dashboardFilter__event dashboardFilter__event-mobile'
      >
        <CustomSVGs svg={eventSVG} />
      </div>

      {/* ------custom event popup start-------- */}
      <Popup
        styles={{
          backgroundColor: 'var(--sidebar-background-color)',
        }}
        setTrigger={toggleCustomEventPopupModal}
        trigger={isCustomEventPopupOpen}
      >
        <div className='dashboardFilter__event--popup'>
          <div className='dashboardFilter__event--popup-text'>
            <h3>{t('SelectEvent')}</h3>
            <span>{t('ChooseTheEventYouWantToAnalyzeInTheDashboard')}</span>
          </div>

          <div className='dashboardFilter__event--popup-list'>
            {eventFilterOptionsArr.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    dashboardEventFilterHandler(item.OptionValue);
                    toggleCustomEventPopupModal();
                  }}
                  className={`dashboardFilter__event--popup-list-item ${
                    eventFilter === item.OptionValue ? 'selected' : ''
                  }`}
                >
                  <span>{item.OptionName}</span>
                </div>
              );
            })}
          </div>
        </div>
      </Popup>
      {/* ------custom event popup end-------- */}
    </>
  );
};

export default DashboardFilterEvent;
