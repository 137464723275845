import { useState, useEffect, CSSProperties } from 'react';
import {
  initialCatalogAndCartInputData,
  initialCheckoutInputData,
  initialGeneralInputData,
  initialInputData,
  initialProductInputData,
  prefillInputDataWithDefaultConfigsForCMS,
  transformInputData,
} from '../../../../lib/constants/trackingClassesData';
import NormalInput from '../../../../ui/inputs/NormalInput';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import { useTranslation } from 'react-i18next';
import post_integration_api_call from '../../../../lib/api/integration/post_integration_api_call';
import Popup from '../../../../ui/popup/Popup';
import CssClassesWarningMsg from './CssClassesWarningMsg';
import CssClassesHelp from './CssClassesHelp';
import NormalSelectInput from '../../../../ui/inputs/NormalSelectInput';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import store, { RootState } from '../../../../store/store';
import { setMessage } from '../../../../store/reducers/appSlice';
import TagInput from '../../../../ui/inputs/TagInput';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { decodeConfigurationsResponse } from '../../../../lib/helper-functions/decodeConfigurationsResponse';
import { useSelector } from 'react-redux';
import {
  downloadSVG,
  importSVG,
  writeSVG,
} from '../../../../lib/allCustomSVGs';
import HeaderStoreButton from '../../../../ui/buttons/headerStoreButton';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import ImageInput from '../../../../ui/inputs/ImageInput';
import getInitialValuesViaTrackingConfigurations from '../../../../lib/helper-functions/getInitialValuesViaTrackingConfigurations';

interface Props {
  data: any;
  isAutofillEnabled: boolean;
  isTrackerON: boolean;
  setScriptKey: (key: string) => void;
}

function CssClasses({
  data,
  isAutofillEnabled,
  isTrackerON,
  setScriptKey,
}: Props) {
  const userCompany = useSelector((state: RootState) => state.user.company);
  const [selectedFile, setSelectedFile] = useState();
  const [previewFile, setPreviewFile] = useState<string>();
  const [isErrorFile, setIsErrorFile] = useState(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState<boolean>(false);
  const [isFormErrorWarningOpen, setIsFormErrorWarningOpen] = useState(false);
  const [isEveryInputNull, setIsEveryInputNull] = useState(true);
  const [resetTagIncrement, setResetTagIncrement] = useState(0);
  const [trackingInputValues, setTrackingInputValues] = useState<any>(
    data?.length > 0
      ? getInitialValuesViaTrackingConfigurations(
          data[data?.length - 1]?.configuration,
          transformInputData(initialInputData)
        )
      : transformInputData(initialInputData)
  );
  const [
    isDefaultPaymentStatusTrackingEnabled,
    setIsDefaultPaymentStatusTrackingEnabled,
  ] = useState<boolean>(
    data?.length > 0
      ? data[data?.length - 1]?.configuration
          ?.is_default_payment_status_tracking_enabled === 'true'
        ? true
        : false
      : false
  );

  const [isDuplicateValue, setIsDuplicateValue] = useState(false);
  const [isEmptyValue, setIsEmptyValue] = useState(false);

  const [paymentTrackingMethodComplete, setPaymentTrackingMethodComplete] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusCompletePaymentTrackingMechanism']
      )
        ? trackingInputValues['statusCompletePaymentTrackingMechanism']
        : 'null'
    );

  const [paymentTrackingMethodFailed, setPaymentTrackingMethodFailed] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusFailedPaymentTrackingMechanism']
      )
        ? trackingInputValues['statusFailedPaymentTrackingMechanism']
        : 'null'
    );

  const [paymentTrackingMethodExpired, setPaymentTrackingMethodExpired] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusExpiredPaymentTrackingMechanism']
      )
        ? trackingInputValues['statusExpiredPaymentTrackingMechanism']
        : 'null'
    );

  const [paymentTrackingMethodCancel, setPaymentTrackingMethodCancel] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusCancelPaymentTrackingMechanism']
      )
        ? trackingInputValues['statusCancelPaymentTrackingMechanism']
        : 'null'
    );

  const [
    paymentTrackingMethodCompleteRequired,
    setPaymentTrackingMethodCompleteRequired,
  ] = useState<boolean>(false);

  const { t } = useTranslation();

  //? Currently only WooCommerce and LightSpeed are supported (default CSS configs prefill & default payment tracking)
  const cmsType =
    data?.length > 0
      ? data[data?.length - 1]?.additional_configs?.CMS_type === 'woocommerce'
        ? 'WooCommerce'
        : data[data?.length - 1]?.additional_configs?.CMS_type === 'lightspeed'
        ? 'LightSpeed'
        : ''
      : '';

  useEffect(() => {
    //? If the CMS type is not supported, disable the default payment status tracking
    if (!isNotEmptyString(cmsType)) {
      setIsDefaultPaymentStatusTrackingEnabled(false);
    }
  }, []);

  const toggleWarningPopupModal = () => {
    setIsFormErrorWarningOpen(!isFormErrorWarningOpen);
  };

  const onInputChange = (e: any, value: string) => {
    setTrackingInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  const selectedTags = (tags: string[], id: string) => {
    setTrackingInputValues((prev: any) => ({
      ...prev,
      [id]: JSON.stringify(tags),
    }));
  };

  const getInitialTags = (value: any) => {
    if (typeof value === 'object' && Array.isArray(value)) {
      return value;
    }
    try {
      const parsedValue = JSON.parse(value);
      return typeof parsedValue === 'object' && Array.isArray(parsedValue)
        ? parsedValue
        : [value];
    } catch (error) {
      return [value];
    }
  };

  useEffect(() => {
    if (!isFormErrorWarningOpen) {
      setIsDuplicateValue(false);
      setIsEmptyValue(false);
    }
  }, [isFormErrorWarningOpen]);

  async function onConfirmHandler() {
    const autofillEnabledObj: { is_autofill_enabled: 'true' | 'false' } = {
      is_autofill_enabled: isAutofillEnabled ? 'true' : 'false',
    };
    const trackerSwitchObj: { is_tracker_active: 'true' | 'false' } = {
      is_tracker_active: isTrackerON ? 'true' : 'false',
    };
    try {
      const sendingData: any = {
        name: 'checkout-tracking-class-names',
        type: 'tracker',
        configuration: isEveryInputNull
          ? { key: 'value' }
          : {
              ...trackingInputValues,
              is_default_payment_status_tracking_enabled:
                isDefaultPaymentStatusTrackingEnabled ? 'true' : 'false',
              statusCompletePaymentTrackingMechanism:
                paymentTrackingMethodComplete !== 'null' &&
                paymentTrackingMethodComplete !== null
                  ? paymentTrackingMethodComplete
                  : '',
              statusFailedPaymentTrackingMechanism:
                paymentTrackingMethodFailed !== 'null' &&
                paymentTrackingMethodFailed !== null
                  ? paymentTrackingMethodFailed
                  : '',
              statusExpiredPaymentTrackingMechanism:
                paymentTrackingMethodExpired !== 'null' &&
                paymentTrackingMethodExpired !== null
                  ? paymentTrackingMethodExpired
                  : '',
              statusCancelPaymentTrackingMechanism:
                paymentTrackingMethodCancel !== 'null' &&
                paymentTrackingMethodCancel !== null
                  ? paymentTrackingMethodCancel
                  : '',
            },
      };

      if (data?.length > 0) {
        sendingData['_method'] = 'put';
      }

      const customMsg = t('CheckoutTrackingDataSuccess-msg');

      const res = await post_integration_api_call(
        sendingData,
        data?.length > 0 ? data[data?.length - 1]?.slug : undefined,
        autofillEnabledObj,
        trackerSwitchObj,
        customMsg
      );
      if (res?.api_key) {
        setScriptKey(res?.api_key);
      }
    } catch (exception) {
      console.log('Error during authentication request');
      // submitLogToBugsnag('error', `${exception}`);
    }
  }

  function inputValidating(inputValuesObj: any) {
    if (
      (!paymentTrackingMethodComplete ||
        paymentTrackingMethodComplete === 'null' ||
        paymentTrackingMethodComplete === null) &&
      !isDefaultPaymentStatusTrackingEnabled
    ) {
      setPaymentTrackingMethodCompleteRequired(true);
      store.dispatch(
        setMessage({
          message:
            t('TrackingMechanismStatus', {
              status: 'Complete',
            }) +
            ' ' +
            t('IsRequired'),
          messageType: 'error',
        })
      );
      return;
    }

    const valueSet = new Set();
    let isEmpty = false;
    let isDuplicate = false;

    const isEverythingNull = !Object.values(inputValuesObj).some(
      (value) => value !== ''
    );
    setIsEveryInputNull(isEverythingNull);

    for (const key in inputValuesObj) {
      const currentValue = inputValuesObj[key];

      if (valueSet.has(currentValue)) {
        isDuplicate = true;
        break;
      } else if (currentValue === '') {
        isEmpty = true;
      } else {
        valueSet.add(currentValue);
      }
    }

    if (isDuplicate) {
      setIsDuplicateValue(true);
      toggleWarningPopupModal();
    } else if (isEmpty) {
      setIsEmptyValue(true);
      toggleWarningPopupModal();
    } else {
      onConfirmHandler();
    }
  }

  function onSubmitHandler() {
    inputValidating(trackingInputValues);
  }

  const sectionTitleStyle: CSSProperties = {
    marginTop: '2rem',
    marginLeft: '1rem',
    textDecoration: 'underline',
    fontSize: '1.6rem',
  };

  //*-------------------------- Import/Export CSS Configs - Start ----------------------------- *//
  const getLabelByKey = (key: string): string | undefined => {
    const item = initialInputData.find((el) => el.name === key);
    return item ? item.label : '';
  };

  const exportToExcel = (data: any[], sheetName: string, fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!cols'] = [{ wch: 50 }, { wch: 50 }, { wch: 100 }];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };

  useEffect(() => {
    if (selectedFile) {
      handleImport(selectedFile);
    }
  }, [selectedFile]);

  const sampleTemplateExport = () => {
    const data = Object.entries(trackingInputValues)
      .filter(([key]) => !key.startsWith('status', 0))
      .map(([key]) => ({
        key,
        label: getLabelByKey(key),
        value: JSON.stringify([
          '.sample-class-1',
          '#sample-id-2',
          '#sample-id-4 .sample-class-3',
        ]),
      }));

    exportToExcel(
      data,
      'AdUp-Sample-Template',
      'AdUp-Sample-Tracking-Configurations-Template.xlsx'
    );
  };

  const handleExport = () => {
    const data = Object.entries(trackingInputValues)
      .filter(([key]) => !key.startsWith('status', 0))
      .map(([key, value]) => ({
        key,
        label: getLabelByKey(key),
        value: value || '',
      }));

    exportToExcel(
      data,
      userCompany?.name ?? 'AdUp',
      `${userCompany?.name ?? 'AdUp'}-Tracking-Configurations-by-AdUp.xlsx`
    );
  };

  const handleImport = (file: any) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      const newInputValues: any = { ...trackingInputValues };
      jsonData.forEach((item: any) => {
        const elementsArray = decodeConfigurationsResponse(item.value);

        //? Filter out invalid CSS selectors
        const filteredElementsArray = elementsArray.filter(
          (htmlSelector: string) =>
            /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/.test(
              htmlSelector
            )
        );

        newInputValues[item.key] = JSON.stringify(filteredElementsArray);
      });

      setTrackingInputValues(newInputValues);
    };
    reader.readAsArrayBuffer(file);
  };
  //*-------------------------- Import/Export CSS Configs - End ----------------------------- *//

  return (
    <form id='Edit-tracking-class-input-form' className='normalForm'>
      <div className='normalForm__top'>
        {/* ------------------------------------------------------- */}
        <h2>{t('GetSupport')}</h2>
        <hr />

        <CssClassesHelp />
      </div>
      {/* ======================================================== */}
      <div className='normalForm__center'>
        <h2>{t('ManageTrackingClassNames')}</h2>
        <hr />
        <h3 className='margin-top-2rem'>
          {t('CheckoutTrackingAddClassesInstructions1')}
        </h3>
        <ol style={{ marginLeft: '2rem' }}>
          {/* <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions2-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions2-2')}{' '}
          </li> */}
          <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions6-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions6-2')}{' '}
          </li>
          <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions3-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions3-2')}{' '}
          </li>
          <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions4-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions4-2')}{' '}
          </li>
          <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions5-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions5-2')}{' '}
          </li>
        </ol>

        {/* ======================================================== */}

        {isNotEmptyString(cmsType) && (
          <div
            className='normalForm__top--container1-left'
            style={{ paddingBottom: '10px' }}
          >
            <HeaderStoreButton
              value={t('PrefillDefaultConfigurations') + ` ${cmsType}`}
              icon={<CustomSVGs svg={writeSVG} />}
              type='button'
              onClick={() => {
                setTrackingInputValues(
                  prefillInputDataWithDefaultConfigsForCMS(
                    cmsType.toLocaleLowerCase()
                  )
                );
                setIsDefaultPaymentStatusTrackingEnabled(true);
              }}
            />
          </div>
        )}

        <div
          className='normalForm__top--container1-left'
          style={{ paddingBottom: '10px' }}
        >
          {!isFileUploadOpen && (
            <HeaderStoreButton
              value={t('ImportCSSConfigurations')}
              icon={<CustomSVGs svg={importSVG} />}
              type='button'
              onClick={() => {
                setIsFileUploadOpen(true);
              }}
            />
          )}
          {isFileUploadOpen && (
            <>
              <div style={{ maxWidth: '40rem' }}>
                <ImageInput
                  isFileUpload={true}
                  mainText={t('ImportCSSConfigurations')}
                  subText={`${t('AcceptedFileTypes')} (.xlsx, .csv)`}
                  acceptedFileTypes={'.xlsx, .xls, .csv'}
                  id={'import-csv'}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  preview={previewFile}
                  setPreview={setPreviewFile}
                  fileError={isErrorFile}
                  setFileError={setIsErrorFile}
                  manyImages={false}
                  removeImage={() => {
                    setSelectedFile(undefined);
                  }}
                  onClose={() => {
                    setIsFileUploadOpen(false);
                  }}
                />
              </div>
              <div
                className='imageInput-outer'
                style={{
                  maxWidth: '40rem',
                  maxHeight: '3.5rem',
                  marginTop: '-0.8rem',
                  cursor: 'pointer',
                }}
                onClick={sampleTemplateExport}
              >
                <button
                  type='button'
                  className='headerStoreButtonWithoutBorder'
                >
                  {<CustomSVGs svg={downloadSVG} />}
                  <p>{t('DownloadTemplate')}</p>
                </button>
              </div>
            </>
          )}
        </div>

        <div className='normalForm__top--container1-left'>
          <HeaderStoreButton
            value={t('ExportCSSConfigurations')}
            icon={<CustomSVGs svg={downloadSVG} />}
            type='button'
            onClick={() => {
              handleExport();
            }}
          />
        </div>

        <h3 style={sectionTitleStyle}>{t('GeneralElements')}</h3>
        <div className='normalForm__container3'>
          {initialGeneralInputData?.map((el: any, index: number) => {
            if (!el?.is_hidden) {
              return (
                <div className='normalForm__container3--item' key={index}>
                  <div className='normalForm__container3--item-content'>
                    <TagInput
                      selectedTags={selectedTags}
                      id={`${el?.name}`}
                      initialTags={
                        trackingInputValues && trackingInputValues[el?.name]
                          ? getInitialTags(trackingInputValues[el?.name])
                          : []
                      }
                      label={el?.label}
                      innerPlaceholder={t('MultipleCssClassesPlaceholder')}
                      required={el?.is_required ? true : false}
                      // autoFocus={index === 0 ? true : false}
                      disabled={el?.is_active ? false : true}
                      validationText={t('CssClassInput-validation')}
                      pattern={
                        /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/
                      }
                      resetTagIncrement={resetTagIncrement}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>

        {/* ======================================================== */}

        <h3 style={sectionTitleStyle}>{t('ProductElements')}</h3>
        <div className='normalForm__container3'>
          {initialProductInputData?.map((el: any, index: number) => {
            if (!el?.is_hidden) {
              return (
                <div className='normalForm__container3--item' key={index}>
                  <div className='normalForm__container3--item-content'>
                    <TagInput
                      selectedTags={selectedTags}
                      id={`${el?.name}`}
                      initialTags={
                        trackingInputValues && trackingInputValues[el?.name]
                          ? getInitialTags(trackingInputValues[el?.name])
                          : []
                      }
                      label={el?.label}
                      innerPlaceholder={t('MultipleCssClassesPlaceholder')}
                      required={el?.is_required ? true : false}
                      // autoFocus={index === 0 ? true : false}
                      disabled={el?.is_active ? false : true}
                      validationText={t('CssClassInput-validation')}
                      pattern={
                        /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/
                      }
                      resetTagIncrement={resetTagIncrement}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>

        {/* ======================================================== */}

        <h3 style={sectionTitleStyle}>{t('CatalogAndCartElements')}</h3>
        <div className='normalForm__container3'>
          {initialCatalogAndCartInputData?.map((el: any, index: number) => {
            if (!el?.is_hidden) {
              return (
                <div className='normalForm__container3--item' key={index}>
                  <div className='normalForm__container3--item-content'>
                    <TagInput
                      selectedTags={selectedTags}
                      id={`${el?.name}`}
                      initialTags={
                        trackingInputValues && trackingInputValues[el?.name]
                          ? getInitialTags(trackingInputValues[el?.name])
                          : []
                      }
                      label={el?.label}
                      innerPlaceholder={t('MultipleCssClassesPlaceholder')}
                      required={el?.is_required ? true : false}
                      // autoFocus={index === 0 ? true : false}
                      disabled={el?.is_active ? false : true}
                      validationText={t('CssClassInput-validation')}
                      pattern={
                        /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/
                      }
                      resetTagIncrement={resetTagIncrement}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>

        {/* ======================================================== */}

        <h3 style={sectionTitleStyle}>{t('CheckoutElements')}</h3>
        <div className='normalForm__container3'>
          {initialCheckoutInputData?.map((el: any, index: number) => {
            if (!el?.is_hidden) {
              return (
                <div className='normalForm__container3--item' key={index}>
                  <div className='normalForm__container3--item-content'>
                    <TagInput
                      selectedTags={selectedTags}
                      id={`${el?.name}`}
                      initialTags={
                        trackingInputValues && trackingInputValues[el?.name]
                          ? getInitialTags(trackingInputValues[el?.name])
                          : []
                      }
                      label={el?.label}
                      innerPlaceholder={t('MultipleCssClassesPlaceholder')}
                      required={el?.is_required ? true : false}
                      // autoFocus={index === 0 ? true : false}
                      disabled={el?.is_active ? false : true}
                      validationText={t('CssClassInput-validation')}
                      pattern={
                        /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/
                      }
                      resetTagIncrement={resetTagIncrement}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>

      <Popup
        styles={{
          backgroundColor: '#fff',
        }}
        setTrigger={toggleWarningPopupModal}
        trigger={isFormErrorWarningOpen}
      >
        <CssClassesWarningMsg
          handler={onConfirmHandler}
          closeModal={toggleWarningPopupModal}
          mainTitle={
            isEmptyValue
              ? t('EmptyFieldsWarningTitle')
              : isDuplicateValue
              ? t('DuplicateFieldsWarningTitle')
              : ''
          }
          subTitle1={
            isEmptyValue
              ? t('EmptyFieldsWarningSubTitle1')
              : isDuplicateValue
              ? t('DuplicateFieldsWarningSubTitle1')
              : ''
          }
        />
        <div className='warning'></div>
      </Popup>

      <div className='normalForm__center'>
        {/* ------------------------------------------------------- */}
        <h2>{t('ManagePaymentStatusTracking')}</h2>
        <hr />
        {isNotEmptyString(cmsType) && (
          <div
            className='shopPage__container1-alignLeft'
            style={{
              marginBottom: isDefaultPaymentStatusTrackingEnabled
                ? '4rem'
                : '0rem',
            }}
          >
            <ToggleSwitch
              isOn={isDefaultPaymentStatusTrackingEnabled}
              title={t('UseDefaultPaymentStatusTracking') + ` ${cmsType}`}
              onClick={() => {
                setIsDefaultPaymentStatusTrackingEnabled(
                  !isDefaultPaymentStatusTrackingEnabled
                );
              }}
              classes='margin-top-2rem'
              id='notification-settings-is-custom-message'
            />
          </div>
        )}
        {!isDefaultPaymentStatusTrackingEnabled && (
          <>
            <h3 className='margin-top-2rem'>
              {t('ManagePaymentStatusTrackingSub')}
            </h3>
            {/* ------------------------------------------------------- */}
            {
              <>
                {/* //*--------------------------PaymentStatus-complete----------------------------- */}
                <div className='normalForm__container1'>
                  <div className='normalForm__container1--left'>
                    <NormalInput
                      id={`add-tracking-integration-payment-complete`}
                      value={t('PaymentStatus-complete')}
                      placeholder={t('PaymentStatus')}
                      disabled={true}
                      style={{ color: 'green' }}
                    />
                  </div>
                  <div className='normalForm__container1--left'>
                    <NormalSelectInput
                      defaultValue={paymentTrackingMethodComplete}
                      setSelect={(input: string) => {
                        setPaymentTrackingMethodComplete(input);
                        setPaymentTrackingMethodCompleteRequired(false);
                      }}
                      required={paymentTrackingMethodCompleteRequired}
                      selectOptionArr={[
                        {
                          OptionValue: 'null',
                          OptionName: t('SelectOption'),
                          OptionDisabled: true,
                        },
                        {
                          OptionValue: 'css',
                          OptionName: t('PaymentStatusTracking-Option-1'),
                        },
                        {
                          OptionValue: 'urlParam',
                          OptionName: t('PaymentStatusTracking-Option-2'),
                        },
                        {
                          OptionValue: 'redirectURL',
                          OptionName: t('PaymentStatusTracking-Option-3'),
                        },
                      ]}
                      placeholder={t('TrackingMechanismStatus', {
                        status: 'Complete',
                      })}
                      id={'statusCompletePaymentTrackingMechanism'}
                    />
                  </div>
                </div>
                <div className='normalForm__container1'>
                  {paymentTrackingMethodComplete === 'css' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(
                                e,
                                'statusCompletePaymentCSSElement'
                              ),
                          ]}
                          id={`statusCompletePaymentCSSElement`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusCompletePaymentCSSElement'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentCSSElement', {
                            status: 'Complete',
                          })}
                          innerPlaceholder={t('CssClassesPlaceholder')}
                          required={true}
                          title={t('CssClassInput-validation')}
                          pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                        />
                      </div>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(
                                e,
                                'statusCompletePaymentPreDefinedText'
                              ),
                          ]}
                          id={`statusCompletePaymentPreDefinedText`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusCompletePaymentPreDefinedText'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentPreDefinedText', {
                            status: 'Complete',
                          })}
                          required={true}
                        />
                      </div>
                    </>
                  )}
                  {paymentTrackingMethodComplete === 'urlParam' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(e, 'statusCompletePaymentURLparam'),
                          ]}
                          id={`statusCompletePaymentURLparam`}
                          value={
                            trackingInputValues
                              ? trackingInputValues?.statusCompletePaymentURLparam
                              : ''
                          }
                          placeholder={t('PaymentURLparam', {
                            status: 'Complete',
                          })}
                          innerPlaceholder={t('URLparamPlaceholder')}
                          required={true}
                          title={t('URLparam-validation')}
                          pattern='^[\w]+[\w\?&]*=[\w]+$'
                        />
                      </div>
                    </>
                  )}
                  {paymentTrackingMethodComplete === 'redirectURL' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(
                                e,
                                'statusCompletePaymentUniqueURL'
                              ),
                          ]}
                          id={`statusCompletePaymentUniqueURL`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusCompletePaymentUniqueURL'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentUniqueURL', {
                            status: 'Complete',
                          })}
                          // innerPlaceholder='https://www.example.com'
                          required={true}
                          // title={t('Expected-URL-Format-validation')}
                          // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                        />
                      </div>
                    </>
                  )}
                </div>

                <br />

                {/* //*--------------------------PaymentStatus-failed----------------------------- */}
                <div className='normalForm__container1'>
                  <div className='normalForm__container1--left'>
                    <NormalInput
                      id={`add-tracking-integration-payment-failed`}
                      value={t('PaymentStatus-failed')}
                      placeholder={t('PaymentStatus')}
                      disabled={true}
                      style={{ color: 'red' }}
                    />
                  </div>
                  <div className='normalForm__container1--left'>
                    <NormalSelectInput
                      defaultValue={paymentTrackingMethodFailed}
                      setSelect={setPaymentTrackingMethodFailed}
                      selectOptionArr={[
                        {
                          OptionValue: 'null',
                          OptionName: t('SelectOption'),
                        },
                        {
                          OptionValue: 'css',
                          OptionName: t('PaymentStatusTracking-Option-1'),
                        },
                        {
                          OptionValue: 'urlParam',
                          OptionName: t('PaymentStatusTracking-Option-2'),
                        },
                        {
                          OptionValue: 'redirectURL',
                          OptionName: t('PaymentStatusTracking-Option-3'),
                        },
                      ]}
                      placeholder={t('TrackingMechanismStatus', {
                        status: 'Failed',
                      })}
                      id={'statusFailedPaymentTrackingMechanism'}
                    />
                  </div>
                </div>
                <div className='normalForm__container1'>
                  {paymentTrackingMethodFailed === 'css' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(e, 'statusFailedPaymentCSSElement'),
                          ]}
                          id={`statusFailedPaymentCSSElement`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusFailedPaymentCSSElement'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentCSSElement', {
                            status: 'Failed',
                          })}
                          innerPlaceholder={t('CssClassesPlaceholder')}
                          required={true}
                          title={t('CssClassInput-validation')}
                          pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                        />
                      </div>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(
                                e,
                                'statusFailedPaymentPreDefinedText'
                              ),
                          ]}
                          id={`statusFailedPaymentPreDefinedText`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusFailedPaymentPreDefinedText'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentPreDefinedText', {
                            status: 'Failed',
                          })}
                          required={true}
                        />
                      </div>
                    </>
                  )}
                  {paymentTrackingMethodFailed === 'urlParam' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(e, 'statusFailedPaymentURLparam'),
                          ]}
                          id={`statusFailedPaymentURLparam`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusFailedPaymentURLparam'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentURLparam', {
                            status: 'Failed',
                          })}
                          innerPlaceholder={t('URLparamPlaceholder')}
                          required={true}
                          title={t('URLparam-validation')}
                          pattern='^[\w]+[\w\?&]*=[\w]+$'
                        />
                      </div>
                    </>
                  )}
                  {paymentTrackingMethodFailed === 'redirectURL' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(e, 'statusFailedPaymentUniqueURL'),
                          ]}
                          id={`statusFailedPaymentUniqueURL`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusFailedPaymentUniqueURL'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentUniqueURL', {
                            status: 'Failed',
                          })}
                          // innerPlaceholder='https://www.example.com'
                          required={true}
                          // title={t('Expected-URL-Format-validation')}
                          // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                        />
                      </div>
                    </>
                  )}
                </div>

                <br />

                {/* //*--------------------------PaymentStatus-expired----------------------------- */}
                <div className='normalForm__container1'>
                  <div className='normalForm__container1--left'>
                    <NormalInput
                      id={`add-tracking-integration-payment-expired`}
                      value={t('PaymentStatus-expired')}
                      placeholder={t('PaymentStatus')}
                      disabled={true}
                      style={{ color: 'orange' }}
                    />
                  </div>
                  <div className='normalForm__container1--left'>
                    <NormalSelectInput
                      defaultValue={paymentTrackingMethodExpired}
                      setSelect={setPaymentTrackingMethodExpired}
                      selectOptionArr={[
                        {
                          OptionValue: 'null',
                          OptionName: t('SelectOption'),
                        },
                        {
                          OptionValue: 'css',
                          OptionName: t('PaymentStatusTracking-Option-1'),
                        },
                        {
                          OptionValue: 'urlParam',
                          OptionName: t('PaymentStatusTracking-Option-2'),
                        },
                        {
                          OptionValue: 'redirectURL',
                          OptionName: t('PaymentStatusTracking-Option-3'),
                        },
                      ]}
                      placeholder={t('TrackingMechanismStatus', {
                        status: 'Expired',
                      })}
                      id={'statusExpiredPaymentTrackingMechanism'}
                    />
                  </div>
                </div>
                <div className='normalForm__container1'>
                  {paymentTrackingMethodExpired === 'css' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(
                                e,
                                'statusExpiredPaymentCSSElement'
                              ),
                          ]}
                          id={`statusExpiredPaymentCSSElement`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusExpiredPaymentCSSElement'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentCSSElement', {
                            status: 'Expired',
                          })}
                          innerPlaceholder={t('CssClassesPlaceholder')}
                          required={true}
                          title={t('CssClassInput-validation')}
                          pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                        />
                      </div>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(
                                e,
                                'statusExpiredPaymentPreDefinedText'
                              ),
                          ]}
                          id={`statusExpiredPaymentPreDefinedText`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusExpiredPaymentPreDefinedText'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentPreDefinedText', {
                            status: 'Expired',
                          })}
                          required={true}
                        />
                      </div>
                    </>
                  )}
                  {paymentTrackingMethodExpired === 'urlParam' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(e, 'statusExpiredPaymentURLparam'),
                          ]}
                          id={`statusExpiredPaymentURLparam`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusExpiredPaymentURLparam'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentURLparam', {
                            status: 'Expired',
                          })}
                          innerPlaceholder={t('URLparamPlaceholder')}
                          required={true}
                          title={t('URLparam-validation')}
                          pattern='^[\w]+[\w\?&]*=[\w]+$'
                        />
                      </div>
                    </>
                  )}
                  {paymentTrackingMethodExpired === 'redirectURL' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(e, 'statusExpiredPaymentUniqueURL'),
                          ]}
                          id={`statusExpiredPaymentUniqueURL`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusExpiredPaymentUniqueURL'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentUniqueURL', {
                            status: 'Expired',
                          })}
                          // innerPlaceholder='https://www.example.com'
                          required={true}
                          // title={t('Expected-URL-Format-validation')}
                          // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                        />
                      </div>
                    </>
                  )}
                </div>

                <br />

                {/* //*--------------------------PaymentStatus-cancel----------------------------- */}
                <div className='normalForm__container1'>
                  <div className='normalForm__container1--left'>
                    <NormalInput
                      id={`add-tracking-integration-payment-cancel`}
                      value={t('PaymentStatus-cancel')}
                      placeholder={t('PaymentStatus')}
                      disabled={true}
                      style={{ color: 'grey' }}
                    />
                  </div>
                  <div className='normalForm__container1--left'>
                    <NormalSelectInput
                      defaultValue={paymentTrackingMethodCancel}
                      setSelect={setPaymentTrackingMethodCancel}
                      selectOptionArr={[
                        {
                          OptionValue: 'null',
                          OptionName: t('SelectOption'),
                        },
                        {
                          OptionValue: 'css',
                          OptionName: t('PaymentStatusTracking-Option-1'),
                        },
                        {
                          OptionValue: 'urlParam',
                          OptionName: t('PaymentStatusTracking-Option-2'),
                        },
                        {
                          OptionValue: 'redirectURL',
                          OptionName: t('PaymentStatusTracking-Option-3'),
                        },
                      ]}
                      placeholder={t('TrackingMechanismStatus', {
                        status: 'Cancel',
                      })}
                      id={'statusCancelPaymentTrackingMechanism'}
                    />
                  </div>
                </div>
                <div className='normalForm__container1'>
                  {paymentTrackingMethodCancel === 'css' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(e, 'statusCancelPaymentCSSElement'),
                          ]}
                          id={`statusCancelPaymentCSSElement`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusCancelPaymentCSSElement'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentCSSElement', {
                            status: 'Cancel',
                          })}
                          innerPlaceholder={t('CssClassesPlaceholder')}
                          required={true}
                          title={t('CssClassInput-validation')}
                          pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                        />
                      </div>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(
                                e,
                                'statusCancelPaymentPreDefinedText'
                              ),
                          ]}
                          id={`statusCancelPaymentPreDefinedText`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusCancelPaymentPreDefinedText'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentPreDefinedText', {
                            status: 'Cancel',
                          })}
                          required={true}
                        />
                      </div>
                    </>
                  )}
                  {paymentTrackingMethodCancel === 'urlParam' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(e, 'statusCancelPaymentURLparam'),
                          ]}
                          id={`statusCancelPaymentURLparam`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusCancelPaymentURLparam'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentURLparam', {
                            status: 'Cancel',
                          })}
                          innerPlaceholder={t('URLparamPlaceholder')}
                          required={true}
                          title={t('URLparam-validation')}
                          pattern='^[\w]+[\w\?&]*=[\w]+$'
                        />
                      </div>
                    </>
                  )}
                  {paymentTrackingMethodCancel === 'redirectURL' && (
                    <>
                      <div className='normalForm__container1--right'>
                        <NormalInput
                          changeListeners={[
                            (e) =>
                              onInputChange(e, 'statusCancelPaymentUniqueURL'),
                          ]}
                          id={`statusCancelPaymentUniqueURL`}
                          value={
                            trackingInputValues
                              ? trackingInputValues[
                                  'statusCancelPaymentUniqueURL'
                                ]
                              : ''
                          }
                          placeholder={t('PaymentUniqueURL', {
                            status: 'Cancel',
                          })}
                          // innerPlaceholder='https://www.example.com'
                          required={true}
                          // title={t('Expected-URL-Format-validation')}
                          // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            }
          </>
        )}
      </div>

      <div className='subPageListDelete__buttons'>
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            setTrackingInputValues(transformInputData(initialInputData));
            setIsDefaultPaymentStatusTrackingEnabled(false);
            setPaymentTrackingMethodComplete('null');
            setPaymentTrackingMethodFailed('null');
            setPaymentTrackingMethodExpired('null');
            setPaymentTrackingMethodCancel('null');
            setPaymentTrackingMethodCompleteRequired(false);
            setResetTagIncrement((prev) => prev + 1);
            setSelectedFile(undefined);
            setPreviewFile(undefined);
            setIsErrorFile(false);
            setIsFileUploadOpen(false);
          }}
          type={'button'}
          value={t('Reset')}
          buttonType={'danger'}
        />
        <MainSquareButton
          type={'button'}
          value={t('Save')}
          onClick={onSubmitHandler}
          form='Edit-tracking-class-input-form'
        />
      </div>
    </form>
  );
}
export default CssClasses;
